import React from 'react';
import { Row, Col, Input, Button } from 'antd';
import Layout from '../components/Layout';

import { useInput } from '../hooks/useInput';

const ContactPage = () => {
  const firstName = useInput('');
  const lastName = useInput('');
  const subject = useInput('');
  const email = useInput('');
  const phone = useInput('');
  const message = useInput('');

  return (
    <Layout title="Contact | I Profess Daily">
      <Row>
        <Col
          xs={{ span: 20, offset: 2 }}
          sm={{ span: 20, offset: 2 }}
          md={{ span: 18, offset: 3 }}
          lg={{ span: 12, offset: 6 }}
          xl={{ span: 12, offset: 6 }}
        >
          <form
            className="form"
            name="contact"
            method="POST"
            data-netlify="true"
          >
            <Input
              type="text"
              name="firstName"
              placeholder="First Name"
              {...firstName}
            />
            <Input
              type="text"
              name="lastName"
              placeholder="Last Name"
              {...lastName}
            />
            <Input
              type="text"
              name="subject"
              placeholder="Subject"
              {...subject}
            />
            <Input type="text" name="email" placeholder="Email" {...email} />
            <Input type="text" name="phone" placeholder="Phone" {...phone} />
            <div style={{ marginTop: 16 }}>
              <Input.TextArea
                name="message"
                placeholder="Message"
                rows={7}
                style={{ width: '100%' }}
                {...message}
              />
            </div>
            <div className="mb-md mt-md end">
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </form>
          <div className="social-wrapper">
            <div className="social">
              <a
                href="https://www.facebook.com/iprofessdaily"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-f" />
              </a>
              <a
                href="https://twitter.com/drvilmavega"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter" />
              </a>
              <a
                href="https://www.instagram.com/vmvdoc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram" />
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default ContactPage;
